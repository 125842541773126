<template>
  <v-app>
    <v-container>
      <v-sheet class="mx-auto my-5" max-width="95%">
        <div class="text-center" v-if="loading === true">
          <v-progress-circular
            :size="70"
            :width="10"
            color="blue"
            indeterminate
          />
          <br />
          {{
            $t(
              "square.お客様の情報を確認中です。処理が完了するまでお待ちください。"
            )
          }}
        </div>
      </v-sheet>
      <v-sheet class="mx-auto my-5" max-width="95%">
        <div class="text-center" v-if="show_error === true">
          {{
            $t(
              "square.決済が正しく行われていません。決済処理はキャンセルされました。クリニックCOMのサポートまでご連絡ください。"
            )
          }}
        </div>
      </v-sheet>

      <v-row justify="center" v-if="loading === false && show_error === false">
        <v-card width="95%" class="mx-auto">
          <v-card-title class="justify-center">
            {{ $t("square.SQUARE決済") }}
          </v-card-title>
          <v-card-text class="text-center">
            <v-card-actions class="justify-center">
              <v-icon x-large color="gray"> mdi-credit-card-outline </v-icon>
            </v-card-actions>
            {{ $t("square.決済代行サービスSQUAREでカード決済を行います。") }}
            <br />
            {{ $t("square.カード情報はクリニックcomには保管されません。") }}

            <form id="payment-form">
              <div id="card-container"></div>
              <v-btn
                id="card-button"
                x-large
                color="primary"
                class="white--text"
                @click="handlePaymentMethodSubmission()"
              >
                {{
                  order.toral_price.toLocaleString("ja-JP", {
                    style: "currency",
                    currency: "JPY",
                  })
                }}
                {{ $t("square.を決済する") }}
              </v-btn>
            </form>
          </v-card-text>
          <div id="payment-status-container"></div>
          <v-card-actions class="mb-5 justify-center">
            <v-btn color="gray" @click="cancelRedirect">
              {{ $t("square.購入を中止して処方箋へ戻る") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  layout: "none",
  data() {
    return {
      loading: true,
      card: null,
      cardButton: null,
      payments: null,
      appId: "",
      locationId: "",
      show_error: false,
    };
  },
  computed: {
    order_id() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.state.userModule.data[this.$store.getters.user.uid];
    },
    order() {
      return this.$store.state.orderModule.data[this.order_id];
    },
    clinic() {
      return this.$store.state.clinicModule.data[this.order.clinic_id];
    },
  },

  created: async function () {
    this.loading = true;
    await this.fetchUsers();
    await this.fetchOrder();
    await this.fetchClinic();
  },
  mounted: async function () {
    this.loading = false;
    try {
      this.payments = window.Square.payments(
        this.clinic.squareAppID,
        this.clinic.squareLocationID
      );
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    this.card;
    try {
      this.card = await this.initializeCard(this.payments);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }
    this.cardButton = document.getElementById("card-button");
  },

  methods: {
    initializeCard: async function (payments) {
      const card = await payments.card();
      await card.attach("#card-container");
      return card;
    },

    handlePaymentMethodSubmission: async function () {
      this.loading = true;
      try {
        // disable the submit button as we await tokenization and make a payment request.
        this.cardButton.disabled = true;
        const token = await this.tokenize(this.card);
        const verificationToken = await this.verifyBuyer(this.payments, token);
        await this.createPayment(token, verificationToken);
      } catch (e) {
        this.cardButton.disabled = false;
        this.displayPaymentResults("FAILURE");
        console.error(e.message);
        this.loading = false;
      }
    },
    tokenize: async function (paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
      }
    },
    verifyBuyer: async function (payments, token) {
      const verificationDetails = {
        amount: "1.00",
        billingContact: {
          givenName: this.order.customerName,
          familyName: "　　　　",
          email: this.order.email,
          phone: this.order.delivery_cellnumber,
          addressLines: [this.order.delivery_address],
          city: "　",
          state: "　",
          countryCode: "JP",
        },
        currencyCode: "JPY",
        intent: "CHARGE",
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    createPayment: async function (token, verificationToken) {
      const body = JSON.stringify({
        locationId: this.locationId,
        sourceId: token,
        verificationToken,
        idempotencyKey: window.crypto.randomUUID(),
        amount: this.order.toral_price,
        clinicId: this.clinic.id,
        orderId: this.order_id,
      });
      const functions = Firebase.app().functions("asia-northeast1");
      try {
        const httpEvent = functions.httpsCallable("squarePayment");
        httpEvent(body)
          .then((res) => {
            if (res.data.statusCode == "200") {
              this.displayPaymentResults(res.data);
            } else {
              this.loading = false;
              this.show_error = true;
              throw new Error(res.data.text());
            }
          })
          .catch((error) => {
            // エラーが発生した場合の処理
            const code = error.code;
            const message = error.message;
            const details = error.details;
            // 特定のエラーコードに基づいて処理を分岐させることができる
            if (code === "internal") {
              // 500エラーに相当する処理
              alert(
                "サーバーエラーが発生しました。決済できませんでした。サポートにご連絡ください。"
              );
              this.loading = false;
              this.show_error = true;
              console.log(error);
            } else {
              // その他のエラーに対する処理
              alert(`エラーが発生しました: ${message}`);
              this.loading = false;
              this.show_error = true;
              console.log(message);
            }
            console.log(`エラー詳細: ${details}`);
          });
      } catch (error) {
        this.loading = false;
        this.show_error = true;
        console.log(error);
        alert("決済できませんでした。サポートにご連絡ください。");
      }
    },

    displayPaymentResults: function (paymentResults) {
      try {
        if (paymentResults.status === "COMPLETED") {
          this.$router.push({
            name: "paid",
            params: { id: this.order.id },
            query: {
              patient_id: this.order.patient_id,
              patientNumber: this.order.patientNumber,
            },
          });
        } else {
          this.loading = false;
          this.is_paymentView = false;
          const statusContainer = document.getElementById(
            "payment-status-container"
          );
          alert("決済できませんでした。サポートにご連絡ください。");
          statusContainer.classList.remove("is-success");
          statusContainer.classList.add("is-failure");
          statusContainer.style.visibility = "visible";
        }
      } catch (error) {
        this.loading = false;
        this.is_paymentView = false;
        const statusContainer = document.getElementById(
          "payment-status-container"
        );
        alert("決済できませんでした。サポートにご連絡ください。");
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
        statusContainer.style.visibility = "visible";
      }
    },

    fetchUsers: async function () {
      await this.$store.dispatch(
        "userModule/fetchById",
        this.$store.getters.user.uid
      );
    },
    fetchOrder: async function () {
      await this.$store.dispatch("orderModule/fetchById", this.order_id);
    },

    fetchClinic: async function () {
      await this.$store.dispatch(
        "clinicModule/fetchById",
        this.order.clinic_id
      );
      this.appId = this.clinic.squareAppID;
      this.locationId = this.clinic.squareLocationID;
    },

    cancelRedirect() {
      this.$router.push({
        name: "prescription",
        params: { id: this.order.prescriptionId },
        query: {
          patient_id: this.order.patient_id,
          patientNumber: this.order.patientNumber,
        },
      });
    },
  },
};
</script>
<style>
#payment-form {
  max-width: 550px;
  min-width: 300px;
  margin: 0px auto;
}

.buyer-inputs {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  border: none;
  margin: 0;
  padding: 0;
}

#card-container {
  margin-top: 45px;
  /* this height depends on the size of the container element */
  /* We transition from a single row to double row at 485px */
  /* Settting this min-height minimizes the impact of the card form loading */
  min-height: 90px;
}

#gift-card-container {
  margin-top: 45px;
  min-height: 90px;
}

@media screen and (max-width: 500px) {
  #card-container {
    min-height: 140px;
  }
}

#ach-button {
  margin-top: 20px;
}

#landing-page-layout {
  width: 80%;
  margin: 150px auto;
  max-width: 1000px;
}

#its-working {
  color: #737373;
}

#example-container {
  width: 100%;
  border: 1px solid #b3b3b3;
  padding: 48px;
  margin: 32px 0;
  border-radius: 12px;
}

#example-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#customer-input {
  margin-bottom: 40px;
}

#card-input {
  margin-top: 0;
  margin-bottom: 40px;
}

#payment-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  width: 225px;
  height: 48px;
  visibility: hidden;
}

#payment-status-container.missing-credentials {
  width: 350px;
}

#payment-status-container.is-success:before {
  content: "";
  background-color: #00b23b;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
}

#payment-status-container.is-success:after {
  content: "Payment successful";
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.is-failure:before {
  content: "";
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.is-failure:after {
  content: "Payment failed";
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.missing-credentials:before {
  content: "";
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.missing-credentials:after {
  content: "applicationId and/or locationId is incorrect";
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.is-success.store-card-message:after {
  content: "Store card successful";
}

#payment-status-container.is-failure.store-card-message:after {
  content: "Store card failed";
}

#afterpay-button {
  height: 40px;
}
</style>
